import React, { useCallback, useEffect, useState } from "react";
import { Field, Form, Formik } from "formik";
import { useCookies } from "react-cookie";
import * as Yup from "yup";
import { getData, patchData, postData } from "../../../../../Services";
import { statusData } from "../../../../../helpers/staticData";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import MyInput from "../../../../atoms/MyInput";
import MyDropdown from "../../../../atoms/MyDropdown";
import { emailRegex } from "../../../../../helpers/expirationTime";
import TagInput from "../../../../atoms/TagInput";
import { useSelector } from "react-redux";

const ClientForm = ({
  onClose,
  initialValues,
  id,
  refetchData,
  setEditData,
}) => {
  const [cookies] = useCookies(["t", "bid", "iso"]);
  const isOwner = cookies.iso;
  let accessData = useSelector((state) => state?.accessData?.accessData);
  accessData = accessData && accessData.length > 0 ? accessData : undefined;
  const roleData = accessData?.["Category Management"];
  const initialData = {
    category: initialValues?.category || "",
    sub_category: initialValues?.sub_category || [],
  };

  const validationSchema = Yup.object({
    category: Yup.string().required("Category is required"),
  });

  const handleSubmit = async (values, { resetForm }, { setValues }) => {
    console.log("entered :", values);

    const res = await postData({
      endpoint: "category/create",
      token: cookies.t,
      params: {
        category: values.category,
        sub_category: values.sub_category,
        business_id: cookies?.bid,
      },
    });
    if (res?.data?.statusCode && res?.data) {
      toast.success("Category Added Successfully", {
        style: confimationStyles,
        duration: 1000,
      });

      resetForm();

      setValues({
        category: "",
        sub_category: [],
      });
      refetchData();
    } else {
      toast.error(res?.data?.message, { style: errorStyles, duration: 1000 });
      onClose();
    }
  };

  const handleEdit = async (values, { resetForm }, { setValues }) => {
    try {
      const res = await patchData({
        endpoint: "category/update",
        token: cookies.t,
        data: values,
        params: {
          id: initialValues._id,
          category: values.category,
          sub_category: values.sub_category || [],
          business_id: cookies?.bid,
        },
      });

      if (res) {
        toast.success("Category Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        resetForm();
        setEditData();
        setValues({
          category: "",
          sub_category: [],
        });
        refetchData();
      } else {
        toast.error(res.message, { style: confimationStyles, duration: 1000 });
      }

      onClose();
    } catch (error) {}
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      key={id}
      enableReinitialize
      //   onSubmit={initialValues ? () => handleEdit() : () => handleSubmit()}
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        setFieldValue,
        handleBlur,
        handleChange,
        isSubmitting,
        resetForm,
        setValues,
        setFieldTouched,
        touched,
      }) => (
        <Form>
          <div className="row mt-3">
            <div className="col-3">
              <MyInput
                type="text"
                id="category"
                name="category"
                totalWidth={true}
                customholder="Enter Category"
              />
            </div>

            <TagInput
              type="text"
              name="sub_category"
              customholder="Enter Sub Category"
              onBlur={(e) => {
                handleBlur(e);
              }}
              InputHeight="small"
              placeholder="Enter Sub Category"
              //   maxTags={5}
              onChange={(tags) => {
                setFieldValue("sub_category", tags);
              }}
              selectedTags={values.sub_category}
              //   tagErrors={
              //     Array.isArray(errors?.interest_party_email) &&
              //     errors.interest_party_email.length > 0
              //       ? errors.interest_party_email
              //           .map((value, index) => (value ? index : -1))
              //           .filter((index) => index !== -1)
              //       : []
              //   }
              //   error={errors.sub_category}
              styles={{ width: "23.9%" }}
            />
            {((roleData && roleData?.["add/edit"]) || isOwner) && (
              <div className={`col-3 mb-3`}>
                {initialValues ? (
                  <button
                    type="submit"
                    className="btn-smlg btn-primary w-50"
                    onClick={() =>
                      handleEdit(values, { resetForm }, { setValues })
                    }
                  >
                    Update
                    {isSubmitting && (
                      <span
                        className="spinner-border spinner-border-sm ms-2 text-light"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                  </button>
                ) : (
                  <div className="d-flex gap-4 ">
                    <button
                      type="button"
                      className="btn-smlg btn-outline-primary w-25 flex-1"
                      disabled={isSubmitting}
                      onClick={() => resetForm()}
                    >
                      Reset
                    </button>

                    <button
                      type="submit"
                      className="btn-smlg btn-primary w-25 flex-1"
                      disabled={isSubmitting}
                      onClick={() =>
                        handleSubmit(values, { resetForm }, { setValues })
                      }
                    >
                      Add
                      {isSubmitting && (
                        <span
                          className="spinner-border spinner-border-sm ms-2 text-light"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      )}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ClientForm;
