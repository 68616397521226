import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import Error from "../../../../atoms/Error";
import MyDropdown from "../../../../atoms/MyDropdown/index";
import MyInput from "../../../../atoms/MyInput/index";
import { countryCodeData } from "../../../../../helpers/countryCodes";
import {
  businessTypes,
  employeeTypes,
  statusData2,
} from "../../../../../helpers/staticData";
import CustomButton from "../../../../atoms/CustomButton";
import { DateSelector } from "../../../../atoms/MonthYearSelector";
import { replaceSpacesInImageUrl } from "../../../../../helpers/replaceSpaceInImageUrl";
import ImageSelector from "../../../../atoms/ImageSelector/ImageSelector";
import { useCookies } from "react-cookie";
import {
  getData,
  postFormData,
  patchFormData,
  postData,
  patchData,
} from "../../../../../Services/index";
import dayjs from "dayjs";
import { statusData } from "../../../../../helpers/staticData";
// import { checkEmailExists } from "../../../../../services/basicGets";
import { capitalizeLetter } from "../../../../../helpers/capatalizeLetters";
import { Heading } from "../../../../atoms/Heading";
import MyTextArea from "../../../../atoms/MyTextArea";
import Breadcrumb from "../../../../atoms/Breadcrumb";

const ImageSelectorStyle = {
  borderRadius: "10px",
  border: "1px solid #EFEFEF",
  padding: "10px",
  width: "200px",
  objectFit: "contain",
};

const AddClients = ({ type = "add" }) => {
  const { id } = useParams();

  const [cookies] = useCookies(["t", "bid"]);
  const [isSaving, setIsSaving] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [emailError, setEmailError] = useState(null);
  const [clientList, setClientList] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const res = await getData({
        endpoint: "employee/getEmployeeByID",
        token: cookies.t,
        params: { id },
      });
      setInitialValues(res?.data);
    };

    const getClientsList = async () => {
      const res = await getData({
        endpoint: "client/getClientList",
        token: cookies.t,
      });
      setClientList(res);
    };

    if (type === "edit") {
      fetchData();
    }
    getClientsList();
  }, []);

  const initialData = {
    name: initialValues?.name || null,
    email: initialValues?.email || null,
    primary_cc: initialValues?.primary_cc || "+91",
    primary_number: initialValues?.primary_number || null,
    status: initialValues?.status || false,
    client_id: initialValues?.client_id || "",
    designation: initialValues?.designation || "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    client_id: Yup.string().required("Client is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    primary_cc: Yup.string().required("Country code is required"),
    primary_number: Yup.string().when("primary_cc", {
      is: "+91",
      then: () =>
        Yup.string()
          .matches(/^[0-9]{10}$/, "Phone number must be 10 digits")
          .required("Primary Phone Number is required"),
      otherwise: () =>
        Yup.string()
          .matches(/^[0-9]{7,15}$/, "Please Enter a valid Phone Number")
          .required("Primary Phone Number is required"),
    }),
  });

  const handleSubmit = async (values) => {
    setIsSaving(true);

    try {
      const res = await postData({
        endpoint: "employee/create",
        token: cookies.t,
        data: {
          ...values,
          name: capitalizeLetter(values.name),
          business_name: capitalizeLetter(values.business_name),
          business_id: cookies.bid,
          emp_type: "Client",
        },
      });
      if (res) {
        toast.success("Employee Created Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleEdit = async (values) => {
    const data = {
      ...values,
      name: capitalizeLetter(values.name),
      business_name: capitalizeLetter(values.business_name),
      business_id: cookies.bid,
    };

    try {
      const res = await patchData({
        endpoint: "employee/update",
        token: cookies.t,
        data: data,
        params: { id: id },
      });
      if (res) {
        toast.success("Employee Updated Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const checkEmail = async (newVal) => {
    const response = await getData({
      endpoint: "employee/checkEmail",
      token: cookies.t,
      params: { email: newVal, business_id: cookies.bid },
    });
    if (response.status && response.data) {
      response.message[0] === "U"
        ? setEmailError(
            "User Is Mapped with Another Business,Use Different Email"
          )
        : setEmailError("Email Already Exists");
    } else {
      setEmailError(null);
    }
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      validateOnChange
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleBlur,
        validateForm,
      }) => (
        <>
          <Breadcrumb
            labels={[
              "Clients",
              `${type === "add" ? "Add Client" : "Edit Client"}`,
            ]}
          />
          <Heading className="h5 mb-3 mt-3">
            {type === "add" ? "Add Client" : "Edit Client"}
          </Heading>
          <hr />
          <div
            className="d-flex flex-wrap w-100 mb-4"
            style={{ columnGap: "1.36%", rowGap: "16px" }}
          >
            <MyDropdown
              placeholder="Select Client"
              data={clientList}
              onChange={(option) => {
                setFieldValue("client_id", option);
              }}
              handleBlur={(e) => {
                setFieldTouched("client_id", true);
                handleBlur(e);
              }}
              selectedValue={values?.client_id}
              name="client_id"
              touched={touched.client_id}
              errors={errors.client_id}
              width="32.426%"
            />

            <MyInput
              type="text"
              name="name"
              customholder="Name"
              width="32.426%"
            />

            <MyInput
              type="text"
              name="email"
              customholder="Email"
              width="32.426%"
              customBlur={(e) => {
                if (initialValues.email !== values.email) {
                  checkEmail(values.email);
                }
              }}
              blurError={emailError}
            />

            <MyInput
              type="text"
              name="designation"
              customholder="Designation"
              width="32.426%"
            />

            <div className="d-flex" style={{ width: "32.426%", gap: "4%" }}>
              <MyDropdown
                data={countryCodeData}
                selectedValue={values.primary_cc}
                name="primary_cc"
                width="23%"
                type={"Country"}
                onChange={(option) => {
                  setFieldValue("primary_cc", option);
                }}
              />

              <MyInput
                type="text"
                name="primary_number"
                customholder="Enter Primary Phone Number"
                width="73%"
                onChange={(e) => {
                  let numericInput = e.target.value.replace(/[^0-9]/g, "");
                  if (values.primary_cc === "+91") {
                    // Enforce a maximum length of 10 digits
                    if (numericInput.length > 10) {
                      numericInput = numericInput.slice(0, 10);
                    }
                    setFieldValue("primary_number", numericInput);
                  } else {
                    setFieldValue("primary_number", numericInput);
                  }
                }}
              />
            </div>

            {type === "edit" && (
              <MyDropdown
                placeholder="Status"
                data={statusData2}
                onChange={(option) => {
                  setFieldValue("status", option === "false" ? false : true);
                }}
                handleBlur={(e) => {
                  setFieldTouched("status", true);
                  handleBlur(e);
                }}
                selectedValue={values?.status === false ? "false" : "true"}
                name="status"
                touched={touched.status}
                errors={errors.status}
                width="32.426%"
              />
            )}
          </div>

          <div className="d-flex gap-3 mt-4">
            {console.log(
              "Isvalid: ",
              !isValid,
              !dirty,
              isSaving,
              emailError !== null
            )}
            {type === "add" ? (
              <CustomButton
                iconRequired={false}
                type="btn-primary"
                buttonType="submit"
                handleClick={() => handleSubmit(values)}
                disabled={!isValid || !dirty || isSaving || emailError !== null}
              >
                Create Client
                {isSaving && (
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </CustomButton>
            ) : (
              <CustomButton
                iconRequired={false}
                type="btn-primary"
                buttonType="submit"
                handleClick={() => handleEdit(values)}
                disabled={!isValid || isSaving || emailError}
              >
                Edit Client
                {isSaving && (
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </CustomButton>
            )}

            <CustomButton
              text="Cancel"
              iconRequired={false}
              handleClick={() => {
                navigate(-1);
              }}
            />
          </div>
        </>
      )}
    </Formik>
  );
};

export default AddClients;
