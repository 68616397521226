import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { getData } from "../../../../../Services";
import { Heading } from "../../../../atoms/Heading";
import style from "./index.module.scss";
// import Breadcrumb from "../../../../molecules/Breadcrumb";
import moment from "moment";
import Breadcrumb from "../../../../atoms/Breadcrumb";

export const LabelDisplay = ({
  label,
  value,
  className,
  modalStyles = false,
}) => {
  return (
    <div
      className={`d-flex ${className}`}
      style={{ width: modalStyles ? "90%" : "50%" }}
    >
      {label && (
        <label className={style.label_view} style={{ width: "50%" }}>
          {label}
        </label>
      )}
      <div className={style.label_description} style={{ width: "48%" }}>
        {value}
      </div>
    </div>
  );
};

const ViewBillInvoice = ({ type = "" }) => {
  const { id } = useParams();
  const [counterData, setCounterData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [cookies] = useCookies(["t"]);
  const getCounterDataById = async () => {
    try {
      const res = await getData({
        endpoint: "bill/getBillByID",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setCounterData(res);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (id && !selectedFile) {
      getCounterDataById();
    }
    if (counterData?.bill_url?.length > 0) {
      setSelectedFile(counterData.bill_url[0]);
    }
  }, [id, counterData]);

  return (
    <>
      <Breadcrumb labels={["Invoices", "View Invoice"]} excludeLastPath />
      <Heading className="addHeaderStyle fs-20">View Bill</Heading>
      <div
        className="w-100 d-flex flex-wrap mb-3"
        style={{ columnGap: "0%", rowGap: "10px" }}
      >
        <LabelDisplay label="Category" value={counterData?.category_name} />
        <LabelDisplay label="Sub Category" value={counterData?.type} />
        <LabelDisplay label="Price" value={counterData.price} />
        <LabelDisplay label="Description" value={counterData.description} />
        {/* <LabelDisplay label="Bill Image" value={counterData.bill_url} />
         */}
      </div>
      <div>
        <label className={style.label_view}>Bill Files</label>
        <div style={{ display: "flex", gap: "5px", flexWrap: "wrap" }}>
          {counterData?.bill_url?.map((url, index) => (
            <button
              key={index}
              onClick={() => setSelectedFile(url)}
              style={{
                padding: "8px 12px",
                background: "#146ef5",
                color: "white",
                border: selectedFile === url ? "1px solid white" : "none",
                outline: selectedFile === url ? "1px solid #146ef5" : "none",
                borderRadius: "4px",
              }}
            >
              {index + 1}
            </button>
          ))}
        </div>
        {selectedFile && (
          <img
            src={selectedFile}
            title="Preview"
            className={style.view_selected_image}
          />
        )}
      </div>
    </>
  );
};
export default ViewBillInvoice;
