import React, { useEffect, useMemo, useState } from "react";
import Tabstrip from "../../../../atoms/tabstrip";
import { Box } from "@mui/material";
import { Heading } from "../../../../atoms/Heading";
import { useLocation, useParams } from "react-router-dom";
import ClientTable from ".";
import { getData } from "../../../../../Services";
import { useCookies } from "react-cookie";

const ViewClientTabs = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("New/Pending");
  const [count, setCount] = useState();

  const { id, isDraft } = useParams();
  const [cookies] = useCookies(["t", "bid"]);

  const getCountsTabs = async () => {
    try {
      const res = await getData({
        endpoint: "bill/countStatus",
        token: cookies.t,
        params: {
          type: "Client",
          business_id: cookies.bid,
        },
      });
      setCount(res);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    setActiveTab("New/Pending");
    getCountsTabs();
  }, []);

  console.log("count is :", count);

  const data = useMemo(
    () => [
      {
        id: 1,
        label: "New/Pending",
        view: <ClientTable type="New/Pending" getCountsTabs={getCountsTabs} />,
      },
      {
        id: 2,
        label: "Booked",
        view: <ClientTable type="Booked" getCountsTabs={getCountsTabs} />,
      },
      {
        id: 3,
        label: "Query Raised",
        view: (
          <>
            <ClientTable type="Query Raised" getCountsTabs={getCountsTabs} />
          </>
        ),
      },
      {
        id: 4,
        label: "Rejected",
        view: (
          <>
            <ClientTable type="Rejected" getCountsTabs={getCountsTabs} />
          </>
        ),
      },
    ],
    []
  );

  const _getComponent = (tabs) => {
    const item = data.find((item) => item.label === tabs);
    return item ? item.view : null;
  };

  const onTabChange = (event, value) => {
    setActiveTab(value);
  };
  const handleAllowId = () => {
    if (location?.state?.edit === "Client") {
      return 1;
    } else if (location?.state?.edit === "Supplier") {
      return 2;
    } else if (localStorage?.state?.edit === "Obligor") {
      return 3;
    } else {
      return 4;
    }
  };

  return (
    <>
      <Heading className="addHeaderStyle">Clients Invoices</Heading>

      <Tabstrip
        activeTab={activeTab}
        onTabChange={onTabChange}
        data={data}
        allowedTabId={location?.state?.edit ? handleAllowId : ""}
        areTabsDisabled={location?.state?.edit ? true : false}
        count={count}
      />
      <Box sx={{ width: "100%" }}>
        <Box>{_getComponent(activeTab)}</Box>
      </Box>
    </>
  );
};

export default ViewClientTabs;
