import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useParams } from "react-router-dom";
import { getData } from "../../../../../Services";
import { Heading } from "../../../../atoms/Heading";
import style from "./index.module.scss";
// import Breadcrumb from "../../../../molecules/Breadcrumb";
import moment from "moment";
import Breadcrumb from "../../../../atoms/Breadcrumb";

export const LabelDisplay = ({
  label,
  value,
  className,
  modalStyles = false,
}) => {
  return (
    <div
      className={`d-flex ${className}`}
      style={{ width: modalStyles ? "90%" : "50%" }}
    >
      {label && (
        <label className={style.label_view} style={{ width: "50%" }}>
          {label}
        </label>
      )}
      <div className={style.label_description} style={{ width: "48%" }}>
        {value}
      </div>
    </div>
  );
};

const ViewEmployee = ({ type = "" }) => {
  const { id } = useParams();
  const [counterData, setCounterData] = useState([]);
  const [cookies] = useCookies(["t"]);
  const getCounterDataById = async () => {
    try {
      const res = await getData({
        endpoint: "employee/getEmployeeByID",
        token: cookies.t,
        params: {
          id: id,
        },
      });
      setCounterData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    if (id) {
      getCounterDataById();
    }
  }, [id]);

  return (
    <>
      <Breadcrumb labels={["Employees", "View Employee"]} excludeLastPath />
      <Heading className="addHeaderStyle fs-20">View Employee</Heading>
      <div
        className="w-100 d-flex flex-wrap mb-3"
        style={{ columnGap: "0%", rowGap: "10px" }}
      >
        <LabelDisplay label="Name" value={counterData.name} />
        <LabelDisplay label="Email" value={counterData.email} />
        <LabelDisplay label="Employee Type" value={counterData.emp_type} />
        {counterData && counterData.emp_type === "Employee" && (
          <LabelDisplay label="Employee ID" value={counterData.emp_id} />
        )}
        {counterData && counterData.emp_type === "Client" && (
          <>
            <LabelDisplay
              label="Business Name"
              value={counterData.business_name}
            />

            <LabelDisplay
              label="Business Type"
              value={counterData.business_type}
            />
          </>
        )}
        {counterData && counterData.primary_number && (
          <LabelDisplay
            label="Primary Phone Number"
            value={`${counterData.primary_cc} ${counterData.primary_number}`}
          />
        )}
        <LabelDisplay label="City" value={counterData.city} />
        <LabelDisplay label="Country" value={counterData.country} />
      </div>
    </>
  );
};
export default ViewEmployee;
