import AppRoutes from "./routes/AppRoutes";
import "./assets/styles/styles.scss";
import { useRoutes } from "react-router-dom";
import routes from "./routes/routes";
import { Toaster } from "react-hot-toast";
function App() {
  const content = useRoutes(routes);
  return (
    <>
      {content} <Toaster />
    </>
  );
}

export default App;
