import { memo, useCallback, useEffect, useState } from "react";
import { Heading } from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import style from "./index.module.scss";
// import NoContentCard from "../../../atoms/NoContentCard";
import { deleteData, getData } from "../../../../Services";
import View from "../../../icons/View";
import EditBlue from "../../../icons/EditBlue";
import CustomButton from "../../../atoms/CustomButton";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Pagination from "../../../atoms/Pagination";
import Delete from "../../../icons/Delete";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/components/toast";
import Modal from "../../../atoms/modals/DeleteModal";
import Edit from "../../../icons/EditBlue";
import CustomMenu from "../../../molecules/CustomMenu";
import HorizontalMenu from "../../../icons/HorizontalMenu";
import FilterComponent from "../../../molecules/filters/FilterComponent";
// import { Rate_TypeData } from "../../../../../utils/staticData";
import EyeIcon from "../../../icons/eye.icon";
import Upload from "../../../icons/Upload";
import { downloadReport } from "../../../../Services/downloadService";
import moment from "moment";
import NoContentCard from "../../../atoms/NoContentCard";
import { expitationTime } from "../../../../helpers/expirationTime";
import { useSelector } from "react-redux";

const Employees = () => {
  const navigate = useNavigate();
  let accessData = useSelector((state) => state?.accessData?.accessData);
  accessData = accessData && accessData.length > 0 ? accessData : undefined;
  const roleData = accessData?.Employees;
  const [cookies, setCookie] = useCookies(["t", "bid", "ibu", "mode", "iso"]);
  const isOwner = cookies.iso;
  const [employeeData, setEmployeeData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchData, setSearchData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [filters, setFilters] = useState({});

  const menu = [
    {
      key: "view",
      icon: <View height="20" width="20" />,
      label: "View",
      onClick: () => {
        navigate(`view/${selectedRow?._id}`);
      },
    },
    {
      icon: <Edit height="20" width="20" />,
      label: "Edit",
      onClick: () => {
        navigate(`edit/${selectedRow?._id}`);
      },
    },
    {
      icon: <Delete />,
      label: "Delete",
      onClick: () => {
        setModalStateDelete({
          isModalOpen: true,
          name: selectedRow.name,
          modalId: selectedRow._id,
        });
        setAnchorEl(null);
      },
    },
  ];

  const GetemployeeData = async () => {
    try {
      const res = await getData({
        endpoint: "employee",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          type: "Employee",
          business_id: cookies?.bid,
        },
      });
      setEmployeeData(res?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    GetemployeeData();
  }, [itemsPerPage, currentPage, searchData, filters]);

  const handleEdit = (data) => {
    navigate(`/dashboard/setting/price/add/${data?._id}`);
  };

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      sortable: true,
    },
    {
      field: "primary_phone",
      headerName: "Primary Phone No.",
      flex: 1,
      renderCell: (params) => {
        return (
          <span>
            {params.row.primary_number && params.row.primary_cc}{" "}
            {params.row.primary_number}
          </span>
        );
      },
    },
    {
      field: "designation",
      headerName: "Designation",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row.status ? (
              <span className="text-success fw-bold">Active</span>
            ) : (
              <span className="text-error fw-bold">Inactive</span>
            )}
          </>
        );
      },
    },
    {
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <span
              type="button"
              onClick={(event) => handleStatusMenu(event, params.row)}
            >
              <HorizontalMenu />
            </span>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              // items={menu}
              items={getMenuList()}
            />
          </>
        );
      },
    },
  ];

  const getMenuList = () => {
    if (
      (roleData &&
        roleData?.["add/edit"] &&
        roleData?.view &&
        roleData?.delete) ||
      isOwner
    ) {
      return menu;
    } else if (roleData && roleData?.["add/edit"] && roleData?.view) {
      return [menu[0], menu[1]];
    } else if (roleData && roleData?.["add/edit"] && roleData?.delete) {
      return [menu[1], menu[2]];
    } else if (roleData && roleData?.view && roleData?.delete) {
      return [menu[0], menu[2]];
    } else if (roleData && roleData?.view) {
      return [menu[0]];
    } else if (roleData && roleData?.delete) {
      return [menu[2]];
    } else if (roleData && roleData?.["add/edit"]) {
      return [menu[1]];
    } else return [];
  };

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No Employees created yet!"
          tag="Add Employee"
          type="Pricing"
          classes="d-flex align-items-center"
          {...((roleData && roleData?.["add/edit"]) || isOwner
            ? {
                handleClick: () => {
                  navigate("/dashboard/employees/add");
                },
                subtitle:
                  "Currently no employee created yet click on a add employee button to create employee",
              }
            : {})}
        />
      </>
    );
  });
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };
  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "employee/delete",
        token: cookies.t,
        params: { id: modalStateDelete?.modalId },
      });
      if (res?.data) {
        toast.success("Employee Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });

        console.log("Response :", res?.data);

        if (res?.data?.mode) {
          setCookie("mode", res?.data?.mode, {
            path: "/",
            expires: expitationTime(),
            // secure: true,
          });
        }
        if (res?.data?.is_business_user) {
          setCookie("ibu", res?.data?.is_business_user, {
            path: "/",
            expires: expitationTime(),
            // secure: true,
          });
        }
        handleCloseDelete();
        GetemployeeData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const filterData = {
    // "Rate Type": Rate_TypeData,
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "price/export",
      token: cookies.t,
      fileName: "Prices",
      params: {
        search: searchData,
        rate_type: filters?.["Rate Type"],
      },
    });

    if (res) {
      toast.success("Prices Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      <div className="headingSearchWrap">
        <div>
          <Heading className="HeadingStyle">Employees</Heading>
        </div>
        <div className="searchButton">
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
              setCurrentPage(1);
            }}
          />

          {((roleData && roleData?.export) || isOwner) && (
            <CustomButton
              text="Export"
              handleClick={() => handleDownload()}
              classes={"filter-contain-btn"}
              iconRequired
              icon={<Upload />}
            />
          )}

          <FilterComponent
            filterData={filterData}
            onFilterChange={(filter) => {
              setFilters(filter);
            }}
          />
          {((roleData && roleData?.["add/edit"]) || isOwner) && (
            <CustomButton
              text="Add Employee"
              type="btn-primary"
              handleClick={() => {
                navigate("add");
              }}
            />
          )}
        </div>
      </div>
      <CustomDatagrid
        rows={employeeData?.[0]?.roleData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
      />
      {employeeData?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={employeeData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title="Employee"
      />
    </>
  );
};
export default Employees;
