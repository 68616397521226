import { Box, Tab, Tabs, Badge } from "@mui/material";
import { color } from "@mui/system";

const _sx = {
  "& .Mui-selected": {
    fontWeight: "600",
    color: "#146EF5 !important",
    backgroundColor: "#fff !important",
  },
  "& button": {
    textTransform: "capitalize !important",
    paddingTop: "12px",
    fontWeight: "500",
    fontFamily: "Instrument Sans !important",
  },
  "& button:active": { border: "none !important", outline: "none !important" },
  "& .Mui-selected:active": {
    border: "none !important",
    outline: "none !important",
  },
  "& .Mui-selected:focus": {
    border: "none !important",
    outline: "none !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#146EF5", // This can be a color of your choice
  },
  "& .MuiButtonBase-root .MuiTab-root": {
    fontFamily: "Instrument Sans !important",
  },
  "& .MuiTabs-scroller": {
    overflowX: "auto !important",
  },
  "&::-webkit-scrollbar": {
    display: "none !important", // Adjust as needed
  },
  "& .MuiBadge-root": {
    marginLeft: "15px",
    marginRight: "9px",
  },
};

const TabStrip = (props) => {
  const {
    activeTab,
    onTabChange,
    data,
    allowedTabId,
    areTabsDisabled,
    margin,
    count,
  } = props;

  const isIdPresent = !!allowedTabId;
  console.log("count :".count);

  return (
    <div id="tabstrip">
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          marginX: margin,
          overflowX: "auto",
        }}
      >
        <Tabs
          value={activeTab}
          onChange={onTabChange}
          sx={_sx}
          style={{ overflowX: "auto" }}
        >
          {data?.map((item) => {
            const isTabDisabled =
              areTabsDisabled &&
              allowedTabId !== undefined &&
              item.id !== allowedTabId;
            const isSelected = activeTab === item?.label;

            return (
              <Tab
                key={item?.id}
                value={item?.label}
                disabled={isTabDisabled}
                label={
                  <Box display="flex" alignItems="center">
                    <span style={{ color: isSelected ? "#146EF5" : "#1B242C" }}>
                      {count
                        ? `${item?.label}(${count[item?.label]})`
                        : item?.label}
                    </span>
                    {item.badge !== undefined && item.badge !== null && (
                      <Badge
                        showZero
                        badgeContent={item.badge}
                        color={isSelected ? "red" : "blue"} // Different badge color
                        sx={{
                          marginLeft: 0,
                          "& .MuiBadge-badge": {
                            backgroundColor: isSelected ? "#146EF5" : "#CFD6DD", // Different badge background color
                            color: isSelected ? "white" : "black",
                          },
                        }}
                      />
                    )}
                  </Box>
                }
              />
            );
          })}
        </Tabs>
      </Box>
    </div>
  );
};

export default TabStrip;
