import React, { useEffect, useRef, useState } from "react";
import Close from "../../icons/Close";
import "./tag.scss"; // Import your CSS file

const TagInput = ({
  placeholder,
  selectedTags,
  onChange,
  id,
  maxTags = Infinity,
  tagErrors = [],
  error,
  styles,
}) => {
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [tagInputHeight, setTagInputHeight] = useState(0);
  // const [error, setError] = useState('');
  const tagsRef = useRef(null);

  useEffect(() => {
    if (selectedTags && selectedTags.length > 0) {
      setTags(selectedTags);
    }
    if (selectedTags.length === 0) {
      setTags([]);
    }
  }, [selectedTags]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setTagInputHeight(entry.contentRect.height);
      }
    });

    if (tagsRef.current) {
      observer.observe(tagsRef.current);
    }

    return () => {
      if (tagsRef.current) {
        observer.unobserve(tagsRef.current);
      }
    };
  }, [tagsRef]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyDown = (e) => {
    if (
      e.key === "Enter" &&
      inputValue.trim() !== "" &&
      tags.length < maxTags
    ) {
      e.preventDefault();
      const newTag = inputValue.trim();
      if (!tags.includes(newTag)) {
        setTags((prevTags) => {
          const updatedTags = [...prevTags, newTag];
          setInputValue("");
          onChange(updatedTags);
          return updatedTags;
        });
      } else {
        setInputValue("");
      }
    }
  };

  const handleTagRemove = (tagToRemove) => {
    const newTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(newTags);
    onChange(newTags);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    // if (error) setError('');
    if (inputValue === "") {
      setIsFocused(false);
    }
  };

  return (
    <>
      <div className="tag-input-container" key={id} style={styles}>
        <div className="floating-label-container">
          <label
            className={`floating-label ${
              isFocused || inputValue || tags.length > 0 ? "focused" : ""
            }`}
          >
            {isFocused || inputValue || tags.length > 0
              ? placeholder.replace("Enter", "").trim()
              : placeholder}
          </label>
          <div
            className={`tag-input ${isFocused ? "focused-tag-input" : ""} ${
              error ? "error-input " : ""
            }`}
            ref={tagsRef}
          >
            <div className="tags-container">
              {tags.length > 0 &&
                tags.map((tag, index) => (
                  <div
                    key={index}
                    className={`tag ${
                      tagErrors.includes(index) ? "tag-error" : ""
                    }`}
                  >
                    {tag}
                    <div
                      className="remove-button"
                      onClick={() => handleTagRemove(tag)}
                    >
                      <Close className="tag-close-btn-container" />
                    </div>
                  </div>
                ))}
            </div>

            {tags.length < maxTags && (
              <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyDown}
                onFocus={handleFocus}
                onBlur={handleBlur}
                placeholder=""
                className="tag-input-field"
                style={{
                  padding:
                    tagInputHeight > 50 ? "0 0 0 13px" : " 20px 0px 0px 3px",
                }}
              />
            )}
          </div>
        </div>
        {error && <div className="error-input-message">{error}</div>}
      </div>
    </>
  );
};

export default TagInput;
