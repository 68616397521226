import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import Error from "../../../../atoms/Error";
import MyDropdown from "../../../../atoms/MyDropdown/index";
import MyInput from "../../../../atoms/MyInput/index";
import { countryCodeData } from "../../../../../helpers/countryCodes";
import {
  businessTypes,
  employeeTypes,
} from "../../../../../helpers/staticData";
import CustomButton from "../../../../atoms/CustomButton";
import { DateSelector } from "../../../../atoms/MonthYearSelector";
import { replaceSpacesInImageUrl } from "../../../../../helpers/replaceSpaceInImageUrl";
import ImageSelector from "../../../../atoms/ImageSelector/ImageSelector";
import { useCookies } from "react-cookie";
import {
  getData,
  postFormData,
  patchFormData,
  postData,
  patchData,
} from "../../../../../Services/index";
import dayjs from "dayjs";
import { statusData } from "../../../../../helpers/staticData";
// import { checkEmailExists } from "../../../../../services/basicGets";
import {
  capitalizeLetter,
  Obj2FormImage,
} from "../../../../../helpers/capatalizeLetters";
import { Heading } from "../../../../atoms/Heading";
import MyTextArea from "../../../../atoms/MyTextArea";
import Breadcrumb from "../../../../atoms/Breadcrumb";
import Modal from "../../../../atoms/modals/Modal";
import style from "./index.module.scss";
import Close from "../../../../icons/Close";
import EyeIcon from "../../../../icons/eye.icon";

const ImageSelectorStyle = {
  borderRadius: "10px",
  border: "1px solid #EFEFEF",
  padding: "10px",
  width: "200px",
  objectFit: "contain",
};

const AddBill = ({ type = "add" }) => {
  const { id } = useParams();

  const [cookies] = useCookies(["t", "bid", "eid"]);
  const [isSaving, setIsSaving] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [emailError, setEmailError] = useState(null);
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getData({
        endpoint: "bill/getBillByID",
        token: cookies.t,
        params: { id },
      });
      setInitialValues(res?.data);
    };
    const getCategoryList = async () => {
      const res = await getData({
        endpoint: "category/getCategoryList",
        token: cookies.t,
        params: { business_id: cookies.bid },
      });
      setCategoryData(res);
    };

    if (type === "edit") {
      fetchData();
    }
    getCategoryList();
  }, []);

  const initialData = {
    name: initialValues?.name || null,
    type: initialValues?.type || null,
    price: initialValues?.price || null,
    description: initialValues?.description || null,
    status: "New/Pending",
    business_id: cookies.bid,
    employee_id: cookies.eid,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    // type: Yup.string().required("Bill Type is required"),
    price: Yup.string().required("Price is required"),
    description: Yup.string().required("Description is required"),
  });

  const handleSubmit = async (values) => {
    setIsSaving(true);

    const formData = new FormData();

    Object.keys(values).forEach((key) => formData.append(key, values[key]));

    files.forEach((file) => formData.append("files", file));

    try {
      const res = await postFormData({
        endpoint: "bill/create",
        token: cookies.t,
        data: formData,
      });
      if (res) {
        toast.success("Bill Created Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleEdit = async (values) => {
    const data = {
      ...values,
      name: capitalizeLetter(values.name),
      business_name: capitalizeLetter(values.business_name),
    };

    try {
      const res = await patchData({
        endpoint: "bill/update",
        token: cookies.t,
        data: data,
        params: { id: id },
      });
      if (res) {
        toast.success("Bill Updated Sucessfully", {
          style: confimationStyles,
          duration: 1000,
        });
        navigate(-1);
      }
    } catch (error) {
      toast.error("An Error Occured Please try again later", {
        style: errorStyles,
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const handleRemoveImage = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  const handleViewFile = (file) => {
    setSelectedFile(file);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedFile(null);
  };

  return (
    <Formik
      initialValues={initialData}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnBlur
      validateOnChange
    >
      {({
        dirty,
        isValid,
        values,
        errors,
        touched,
        setFieldTouched,
        setFieldValue,
        handleBlur,
        validateForm,
      }) => (
        <>
          <Breadcrumb
            labels={["Bills", `${type === "add" ? "Add Bill" : "Edit Bill"}`]}
          />
          <Heading className="h5 mb-3 mt-3">
            {type === "add" ? "Add Bill" : "Edit Bill"}
          </Heading>
          <hr />
          <div
            className="d-flex flex-wrap w-100 mb-4"
            style={{ columnGap: "1.36%", rowGap: "16px" }}
          >
            <MyDropdown
              data={categoryData}
              selectedValue={values.name}
              name="name"
              placeholder={"Select Category"}
              width="32.426%"
              onChange={async (option) => {
                setFieldValue("name", option);
                const res = await getData({
                  endpoint: "category/getSubCategoryList",
                  token: cookies.t,
                  params: { id: option },
                });
                if (res) setSubCategoryData(res);
                else setSubCategoryData([]);
              }}
            />

            {subCategoryData && subCategoryData.length > 0 && (
              <MyDropdown
                data={subCategoryData}
                selectedValue={values.type}
                name="type"
                placeholder={"Select Sub Category"}
                width="32.426%"
                onChange={async (option) => {
                  setFieldValue("type", option);
                }}
              />
            )}

            {/* <MyInput
              type="text"
              name="type"
              customholder="Bill Type"
              width="32.426%"
            /> */}

            <MyInput
              type="text"
              name="price"
              customholder="Price"
              width="32.426%"
            />

            <MyInput
              type="text"
              name="description"
              customholder="Description"
              width="32.426%"
            />

            <div style={{ width: "32.426%" }}>
              <ImageSelector
                placeholder="Upload Supporting Documents"
                handleFile={async (file) => {
                  setFiles((prevFiles) => [...prevFiles, file]);
                }}
                multiple
                touched={touched.client_supporting_docs}
                errors={errors.client_supporting_docs}
              />
            </div>
            {files.map((file, index) => (
              <div
                key={index}
                style={{ width: "32.426%", position: "relative" }}
              >
                <div className={style.imagesNameStyle}>
                  <span className={style.imagesName}>
                    {file.name || file.split("/").pop()}
                  </span>
                  <div
                    className={style.closeIcon}
                    onClick={() => handleViewFile(file)}
                    style={{
                      position: "absolute",
                      top: "8px",
                      right: "30px",
                      cursor: "pointer",
                    }}
                  >
                    <EyeIcon />
                  </div>
                  <div
                    className={style.closeIcon}
                    onClick={() => handleRemoveImage(file)}
                    style={{
                      position: "absolute",
                      top: "8px",
                      right: "5px",
                      cursor: "pointer",
                    }}
                  >
                    <Close />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="d-flex gap-3 mt-4">
            {type === "add" ? (
              <CustomButton
                iconRequired={false}
                type="btn-primary"
                buttonType="submit"
                handleClick={() => handleSubmit(values)}
                disabled={!isValid || !dirty || isSaving}
              >
                Create Bill
                {isSaving && (
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </CustomButton>
            ) : (
              <CustomButton
                iconRequired={false}
                type="btn-primary"
                buttonType="submit"
                handleClick={() => handleEdit(values)}
                disabled={!isValid || isSaving || emailError}
              >
                Edit Bill
                {isSaving && (
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </CustomButton>
            )}

            <CustomButton
              text="Cancel"
              iconRequired={false}
              handleClick={() => {
                navigate(-1);
              }}
            />
          </div>
          {isModalOpen && selectedFile && (
            <Modal onClose={handleCloseModal} isActive={isModalOpen}>
              {selectedFile.type.startsWith("image/") ? (
                <img
                  src={URL.createObjectURL(selectedFile)}
                  alt="Preview"
                  style={{ width: "100%" }}
                />
              ) : (
                <iframe
                  src={URL.createObjectURL(selectedFile)}
                  title="Preview"
                  style={{ width: "100%", height: "500px" }}
                />
              )}
            </Modal>
          )}
        </>
      )}
    </Formik>
  );
};

export default AddBill;
