import { memo, useCallback, useEffect, useState } from "react";
import { Heading } from "../../../../atoms/Heading";
import Search from "../../../../atoms/Search";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import style from "./index.module.scss";
// import NoContentCard from "../../../../atoms/NoContentCard";
import { deleteData, getData, patchData } from "../../../../../Services";
import View from "../../../../icons/View";
import EditBlue from "../../../../icons/EditBlue";
import CustomButton from "../../../../atoms/CustomButton";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Pagination from "../../../../atoms/Pagination";
import Delete from "../../../../icons/Delete";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/components/toast";
import Modal from "../../../../atoms/modals/DeleteModal";
import Edit from "../../../../icons/EditBlue";
import CustomMenu from "../../../../molecules/CustomMenu";
import HorizontalMenu from "../../../../icons/HorizontalMenu";
import FilterComponent from "../../../../molecules/filters/FilterComponent";
// import { Rate_TypeData } from "../../../../../../utils/staticData";
import EyeIcon from "../../../../icons/eye.icon";
import Upload from "../../../../icons/Upload";
import { downloadReport } from "../../../../../Services/downloadService";
import moment from "moment";
import NoContentCard from "../../../../atoms/NoContentCard";
import { useSelector } from "react-redux";

const ClientTable = ({ type, getCountsTabs }) => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["t", "bid", "ibu", "mode", "iso"]);
  const isOwner = cookies.iso;
  let accessData = useSelector((state) => state?.accessData?.accessData);
  accessData = accessData && accessData.length > 0 ? accessData : undefined;
  const roleData = accessData?.["Client Invoices"];
  const [employeeData, setEmployeeData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchData, setSearchData] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalStateDelete, setModalStateDelete] = useState({
    modalId: "",
    modalTitle: "",
    isModalOpen: false, // Set initial state to false
  });
  const [filters, setFilters] = useState({});

  const handleChangeStatus = async (type) => {
    try {
      const res = await patchData({
        endpoint: "bill/update",
        token: cookies.t,
        data: { status: type },
        params: {
          id: selectedRow._id,
        },
      });

      if (res) {
        toast.success("Bill Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        GetemployeeData();
        getCountsTabs();
      } else {
        toast.error(res.message, { style: confimationStyles, duration: 1000 });
      }
    } catch (error) {}
  };

  const menu = [
    // {
    //   key: "view",
    //   icon: <View height="20" width="20" />,
    //   label: "View",
    //   onClick: () => {
    //     navigate(`view/${selectedRow?._id}`);
    //   },
    // },
    // {
    //   icon: <Edit height="20" width="20" />,
    //   label: "Edit",
    //   onClick: () => {
    //     navigate(`edit/${selectedRow?._id}`);
    //   },
    // },
    // {
    //   icon: <Delete />,
    //   label: "Delete",
    //   onClick: () => {
    //     setModalStateDelete({
    //       isModalOpen: true,
    //       name: selectedRow.name,
    //       modalId: selectedRow._id,
    //     });
    //     setAnchorEl(null);
    //   },
    // },
    {
      // icon: <Edit height="20" width="20" />,
      label: "Query Raised",
      onClick: () => {
        handleChangeStatus("Query Raised");
      },
    },
    {
      // icon: <Edit height="20" width="20" />,
      label: "Booked",
      onClick: () => {
        handleChangeStatus("Booked");
      },
    },
    {
      // icon: <Edit height="20" width="20" />,
      label: "Rejected",
      onClick: () => {
        handleChangeStatus("Rejected");
      },
    },
  ];

  const GetemployeeData = async () => {
    try {
      const res = await getData({
        endpoint: "bill",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchData,
          business_id: cookies.bid,
          type: type,
          emp_type: "Client",
          mode: "Invoice",
        },
      });
      setEmployeeData(res?.data);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    GetemployeeData();
  }, [itemsPerPage, currentPage, searchData, filters, type]);

  const handleEdit = (data) => {
    navigate(`/dashboard/setting/price/add/${data?._id}`);
  };

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    {
      field: "name",
      headerName: "Category",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.categoryData && (
              <div>{params?.row?.categoryData?.category}</div>
            )}
          </>
        );
      },
    },
    {
      field: "type",
      headerName: "Sub Category",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            {params?.row?.type && (
              <div>{params?.row?.type !== "null" ? params?.row?.type : ""}</div>
            )}
          </>
        );
      },
    },
    {
      field: "price",
      headerName: "Price",
      flex: 1,
      sortable: true,
    },
    {
      field: "created",
      headerName: "Created By",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params?.row.clientData && (
              <div>{params.row.clientData?.[0]?.name}</div>
            )}
          </>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return <>{params?.row.status && <div>{params.row.status}</div>}</>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            <div>
              <span
                type="button"
                onClick={(event) => handleStatusMenu(event, params.row)}
              >
                <HorizontalMenu />
              </span>
            </div>

            <CustomMenu
              anchorEl={anchorEl}
              open={Boolean(anchorEl) && selectedRow === params.row}
              onClose={handleClose}
              items={getMenuList()}
            />
          </>
        );
      },
    },
  ];

  const getMenuList = () => {
    if (
      type === "New/Pending" &&
      ((roleData && roleData?.["add/edit"]) || isOwner)
    ) {
      return menu;
    } else if (
      type === "Query Raised" &&
      ((roleData && roleData?.["add/edit"]) || isOwner)
    ) {
      return [menu[1], menu[2]];
    } else if (
      type === "Rejected" &&
      ((roleData && roleData?.["add/edit"]) || isOwner)
    ) {
      return [menu[0], menu[1]];
    } else return [];
  };

  // const getMenuList = () => {
  //   if (roleData && roleData?.["add/edit"] && roleData?.delete) {
  //     return menu;
  //   } else if (roleData && roleData?.delete) {
  //     return [menu[1]];
  //   } else if (roleData && roleData?.["add/edit"]) {
  //     return [menu[0]];
  //   } else return [];
  // };

  const CustomNoRowsOverlay = memo(() => {
    return (
      <>
        <NoContentCard
          title="No Invoice added yet!"
          tag="Add Bill"
          type="Pricing"
          classes="d-flex align-items-center"
          // subtitle="Currently no invoice created yet click on a add bill button to create bill"
        />
      </>
    );
  });
  const handleCloseDelete = () => {
    setModalStateDelete(() => ({
      isModalOpen: false,
    }));
  };
  const handleDelete = async () => {
    try {
      const res = await deleteData({
        endpoint: "bill/delete",
        token: cookies.t,
        params: { id: modalStateDelete?.modalId },
      });
      if (res) {
        toast.success("Bill Deleted Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
        handleCloseDelete();
        GetemployeeData();
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1); // Reset current page to 1
    setItemsPerPage(perPage);
  }, []);

  const filterData = {
    // "Rate Type": Rate_TypeData,
  };

  const handleDownload = async () => {
    const res = await downloadReport({
      endpoint: "price/export",
      token: cookies.t,
      fileName: "Prices",
      params: {
        search: searchData,
        rate_type: filters?.["Rate Type"],
      },
    });

    if (res) {
      toast.success("Prices Exported Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
    } else {
      toast("An Error Occured Please Try Again Later.", {
        duration: 1000,
        style: errorStyles,
      });
    }
  };

  return (
    <>
      {/* <div className="headingSearchWrap">
        <div>
          <Heading className="HeadingStyle">Bills</Heading>
        </div>
        <div className="searchButton">
          <Search
            onSearchEmit={(data) => {
              setSearchData(data);
              setCurrentPage(1);
            }}
          />
          <CustomButton
            text="Export"
            handleClick={() => handleDownload()}
            classes={"filter-contain-btn"}
            iconRequired
            icon={<Upload />}
          />

          <FilterComponent
            filterData={filterData}
            onFilterChange={(filter) => {
              setFilters(filter);
            }}
          />

          <CustomButton
            text="Add Bill"
            type="btn-primary"
            handleClick={() => {
              navigate("add");
            }}
          />
        </div>
      </div> */}
      <CustomDatagrid
        rows={employeeData?.[0]?.roleData || []}
        getRowId={(row) => row?._id}
        columns={columns}
        height={() => 50}
        hoverColor="ffffff"
        CustomNoRowsOverlay={CustomNoRowsOverlay}
        className={style.custom_grid_styles}
        onCellClick={(data) => {
          if (
            data?.field !== "actions" &&
            data?.field !== "__check__" &&
            ((roleData && roleData?.["view"]) || isOwner)
          ) {
            navigate(`/dashboard/clientInvoice/viewInvoice/${data?.id}`);
          }
        }}
      />
      {employeeData?.[0]?.pageDetails?.count > 10 && (
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={employeeData?.[0]?.pageDetails?.count}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      )}
      <Modal
        isActive={modalStateDelete.isModalOpen}
        onClose={handleCloseDelete}
        onClick={handleDelete}
        title="Bill"
      />
    </>
  );
};
export default ClientTable;
